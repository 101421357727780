<template>
<job-posting-layout>
  <template #topContent>
    <loader :loading="loading" v-if="loading" />
    <div class="stepContent tw-w-full" v-if="!loading">
      <step-count :count="8"/>
      <step-title title="Qualified Applicants & Deadline" />
      <div class=" tw-flex tw-w-full  tw-flex-col-reverse lg:tw-flex-row">
        <div class="lg:tw-mt-12 lg:tw-w-3/4">
      <div class="tw-flex-col">
        <small style="color: red" v-if="showError.state">{{showError.message}}</small>
        <div class="tw-pt-10">
          <label class="label">Please enter or select the number of qualified candidates you want to interview.</label>
          <div class="tw-mt-5">
            <v-combobox
                v-model="numberOfPersons"
                :items="people"
                placeholder="Enter no. of people"
                outlined
                solo
                class="select-input lg:tw-w-96"
                type='Number'
            ></v-combobox>
          </div>
        </div>
        <div class="tw-pt-10">
          <label class="label ">Set application deadline</label>
          <div class="tw-mt-5">
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="deadlineDate"
                    placeholder="Select deadline"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    solo
                    class="select-input lg:tw-w-96"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="deadlineDate"
                  @input="menu = false"
                  no-title
                  scrollable
                  :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)">
              </v-date-picker>
            </v-menu>

          </div>
        </div>
        <div class="tw-flex tw-items-center">
          <v-checkbox
              v-model="qualified_applicant_reached"
              class="select-input"
              color="#8F47F4"
          >
            <template #label>
              <label class="label tw-cursor-pointer ">Close application after getting qualified applicant</label>
            </template>
          </v-checkbox>

        </div>
        </div>
    </div>

      </div>
    </div>
  </template>
  <template #bottomNav>
    <bottom-navbar @click:back="routeBack" :button-name="$route.query.edit?'Save ':'Next '" @click:next="updateJobById"
                   :previous="!$route.query.edit" :loading="buttonLoading"/>
  </template>
</job-posting-layout>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import BottomNavbar from "../reuseables/BottomNavbar";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
import {getJobById,updateJobById} from "../../../services/api/APIService"
import Loader from "../../UIComponents/reusablesIcon/Loader";
// import SuggestionView from "./SuggestionView";
export default {
  name: "StepEight",
  components: { Loader, StepTitle, StepCount, BottomNavbar, JobPostingLayout},
  data(){
    return{
      people:[3,5,10,15,20,30,40,50,60,70,80,90,100],
      numberOfPersons :null,
      deadlineDate: null,
      menu: false,
      loading : false,
      qualified_applicant_reached : false,
      showError:{
        state : false,
        message: ""
      },
      buttonLoading: false
    }
  },
  methods: {
    toggleIsMaximumNumber(){
      this.qualified_applicant_reached = !this.qualified_applicant_reached
    },
    getJobById(){
      this.loading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobData = {}
      jobData.id=job.id
      jobData.site_name = this.$siteName()
      getJobById(jobData).then(res =>{
        if (res.data){
          this.deadlineDate = res.data.application_deadline
          this.numberOfPersons = res.data.num_of_shortlisted_applicants
          this.qualified_applicant_reached = res.data.close_job_on_number_of_qualified_applicant_reached
        }
        this.loading = false
      })
          .catch(err =>{
            console.log(err.response.data.detail)
            this.loading= false
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    },
    updateJobById(){
      this.buttonLoading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobId = job.id
      let deadlineData = {}
      deadlineData.site_name = this.$siteName()
      if (this.numberOfPersons === 0 || this.numberOfPersons === null){
        this.showError.state = true
        this.showError.message = "Number of qualified applicants can not be 0 or empty"
        this.buttonLoading = false
      }
      else {
        if (this.deadlineDate === null){
          this.showError.message = "Application deadline can not be empty"
          this.showError.state = true
          this.buttonLoading = false
        }
        else {
          this.showError.state = false
          deadlineData.application_deadline = this.deadlineDate
          deadlineData.num_of_shortlisted_applicants = this.numberOfPersons
          deadlineData.close_job_on_number_of_qualified_applicant_reached = this.qualified_applicant_reached
          updateJobById(jobId,deadlineData).then(() => {
            this.buttonLoading = false
            if(this.$route.query.edit){
              this.$router.push({name:'JobSummary'})
            }
            else {
              this.$router.push({name:'Job-stepNine'})
            }
          })
              .catch(err =>{
                console.log(err.response.data.detail)
                this.buttonLoading = false
                this.loading= false
                this.$displaySnackbar({message:err.response.data.detail, success:false})
              })
        }
      }
    },
    routeBack(){
        this.$router.push({name:'Job-stepSeven'})
    }
  },
  created() {
    this.getJobById()
  }
}
</script>

<style scoped>
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot:not(.v-input--is-disabled) {
  border: none !important;
}

</style>